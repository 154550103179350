import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import clsx from "clsx"
import { Link, graphql } from "gatsby"
import YouTube, { YouTubeProps } from "react-youtube"
import reset from "../images/reset-life.gif"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/Layout"
import OnScreen from "../components/OnScreen"
import SEO from "../components/SEO"
import rocketcores from "../images/rocket-cores.gif"
import mind from "../images/mindset.gif"
import emotional from "../images/emotional.gif"
import physical from "../images/physical.gif"
import relationship from "../images/relationship.gif"
import finance from "../images/finance.gif"
import happyMeter from "../images/happy-meter.gif"
import gears from "../images/gears-spinning.gif"
import rocket from "../images/rocket.gif"
import StartButton from "../components/StartButton"
import NewTimeline from "../components/NewTimeline"

const FiveCores: FunctionComponent = () => {
  const [ready, setReady] = useState(false)

  const [rocketShipFlying, setRocketShipFlying] = useState(false)

  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => setRocketShipFlying(false), 2000)
    setTimeout(() => setReady(true), 300)
  }, [])

  function getRocketOffset() {
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 640) {
        return 24
      }

      if (window.innerWidth <= 768) {
        return 64
      }
    }

    return textRef.current?.getBoundingClientRect().top || -1
  }

  const onPlayerReady: YouTubeProps["onReady"] = event => {
    // access to player in all event handlers via event.target
    // event.target.Mute()
    // event.target.setVolume(100);
    // event.target.playVideo();
  }
  const onEnd: YouTubeProps["onEnd"] = event => {
    // access to player in all event handlers via event.target
    event.target.playVideo()
  }

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 1,
      mute: 1,
      rel: 0,
      fs: 0,
      showinfo: 0,
    },
  }

  const newEvents: TimelineEvent[] = [
    {
      color: "teal",
      direction: "NEUTRAL",
      iconImage: mind,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              loading="lazy"
              className="m-auto"
              src={mind}
            />
          ),
          message: (
            <div className="text-link">
              Get your mind working FOR instead of against you by developing a
              growth mindset to allow you to fail forward, overcome obstacles,
              and give fear the finger on your level up journey.
            </div>
          ),
          title: "Mindset Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/mindset-before.webp"
            />
          ),
          message: (
            <div className="text-link m-auto text-coral">
              You're unable to level up your life because of a fixed mindset,
              limiting beliefs, and low self esteem.{" "}
            </div>
          ),
          title: "Mindset Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/mindset-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              Positive mindset, self love, continual self reflection and
              learning new skills to become the best version of yourself.{" "}
            </div>
          ),
          title: "Mindset Core After",
        },
      ],
    },

    {
      color: "teal",
      direction: "NEUTRAL",
      iconImage: finance,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores career finance"
              placeholder="blurred"
              loading="lazy"
              className="m-auto"
              src={finance}
            />
          ),
          message: (
            <div className="text-link">
              Improve your professional life doing what you love and are great
              at, executing your purpose, and exponentially grow your wealth
              along the way.
            </div>
          ),
          title: "Career & Finance Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/finance-before.webp"
            />
          ),
          message: (
            <div className="text-link text-coral">
              You're constantly overwhelmed, procrastinating, and unable to get
              out of your comfort zone to take the necessary action to conquer
              your boss battles.{" "}
            </div>
          ),
          title: "Career & Finance Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/finance-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              You're running a startup business that combines your greatest
              strengths and passions to win the video game of life.{" "}
            </div>
          ),
          title: "Career & Finance Core After",
        },
      ],
    },

    {
      color: "blue",
      direction: "NEUTRAL",
      iconImage: relationship,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores relationship"
              placeholder="blurred"
              loading="lazy"
              className="m-auto"
              src={relationship}
            />
          ),
          message: (
            <div className="text-link">
              Create and maintain deep, fulfilled relationships and gain the
              right allies to help win those boss battles.
            </div>
          ),
          title: "Relationships Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/relationship-before.webp"
            />
          ),
          message: (
            <div className="text-link text-coral">
              You've got poor game mechanics with others, continuously
              struggling to create close friendships and find/maintain healthy
              romantic relationships.{" "}
            </div>
          ),
          title: "Relationships Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/relationship-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              You've developed a daily routine to spend time with those who
              matter most and and have acquired the skills to build strategic
              partnerships for a successful and happy life.{" "}
            </div>
          ),
          title: "Relationships Core After",
        },
      ],
    },

    {
      color: "blue",
      direction: "NEUTRAL",
      iconImage: physical,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores physical health"
              placeholder="blurred"
              loading="lazy"
              className="m-auto"
              src={physical}
            />
          ),
          message: (
            <div className="text-link">
              Take care of your physical self to look good, feel good, and gain
              the energy and stamina to propel you to a lasting and meaningful
              life.
            </div>
          ),
          title: "Physical Health Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/physical-before.webp"
            />
          ),
          message: (
            <div className="text-link text-coral">
              You've become addicted to dopamine hits and low hanging fruit (not
              the good kind) that have compounded your unhealthy habits with
              eating, exercising, and sleep.{" "}
            </div>
          ),
          title: "Physical Health Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/physical-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              Your self aware of the key aspects of your health that you're able
              to personalize a healthy self care routine around to become the
              best version of yourself.{" "}
            </div>
          ),
          title: "Physical Health Core After",
        },
      ],
    },

    {
      color: "blue",
      direction: "NEUTRAL",
      iconImage: emotional,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores emotional health"
              className="m-auto"
              loading="lazy"
              placeholder="blurred"
              src={emotional}
            />
          ),
          message: (
            <div className="text-link">
              Manage stress effectively, express your passions regularly, and
              ensure the world is better for having you in it.
            </div>
          ),
          title: "Emotional Health Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/emotional-before.webp"
            />
          ),
          message: (
            <div className="text-link text-coral">
              You're constantly overwhelmed, stressed, and rarely make time for
              your passions in life.{" "}
            </div>
          ),
          title: "Emotional Health Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/emotional-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              You effectively manage stress and regularly engage in activities
              that power up your video game avatar in order to live a fulfilled
              and meaningful life.
            </div>
          ),
          title: "Emotional Health Core After",
        },
      ],
    },
  ]
  return (
    <Layout absoluteHeader>
      <SEO
        description="Discover the 5 core Areas of Life fueling your life and learn the role each plays in your ability to break earth's gravitational pull for personal development."
        title="5 Core Areas of Life"
        link="https://mooremomentum.com/5-core-areas-of-life/"
      />

      <div className="primary-gradient-bg pb-12 py-32 md:pt-40 lg:pt-36">
        <div className="container mx-auto px-4 relative text-white z-10">
          <div className="mt-8 lg:mt-0">
            <div className="text-center">
              <h1 className="font-Orbitron mb-4 text-shadow text-3xl md:text-4xl lg:text-5xl lg:leading-tight">
                The 5 Core Areas of Life{" "}
              </h1>
            </div>

            <div className="gap-8 grid grid-cols-1 items-center mt-8 lg:mt-8">
              <div className="flex max-w-xl mock-shadow mx-auto slide-in-left w-full sm:order-2 2xl:max-w-3xl">
                <svg
                  className="h-44 w-auto sm:h-64 md:h-64 2xl:h-80"
                  width="198"
                  height="518"
                  viewBox="0 0 198 518"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M190.5 15V7.5H183H115C55.6294 7.5 7.5 55.6294 7.5 115V403C7.5 462.371 55.6294 510.5 115 510.5H183H190.5V503V15Z"
                    fill="#55BEE0"
                    stroke="black"
                    strokeWidth="15"
                  />
                  <circle
                    className="animate-pulse"
                    cx="116.5"
                    cy="137.5"
                    r="39.5"
                    fill="black"
                  />
                  <circle cx="112" cy="240" r="19" fill="black" />
                  <circle cx="77" cy="273" r="19" fill="black" />
                  <circle cx="147" cy="273" r="19" fill="black" />
                  <circle cx="112" cy="309" r="19" fill="black" />
                  <rect
                    x="125"
                    y="359"
                    width="31"
                    height="31"
                    rx="3"
                    fill="black"
                  />
                </svg>

                <div className="bg-black flex-1 py-4 relative">
                  <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-full">
                    {ready && (
                      <YouTube
                        videoId="1yf8nmzbeRk"
                        className="video-iframe h-full"
                        opts={opts}
                        onReady={onPlayerReady}
                        onEnd={onEnd}
                      />
                    )}
                    {/* <div className="wistia_responsive_padding relative iframe-wrapper">
                      <div className="wistia_responsive_wrapper h-full left-0 absolute top-0 w-full">
                        <div className="wistia_embed wistia_async_ppgmrzjgt5 videoFoam=true autoPlay=true h-full relative w-full">
                          <div className="wistia_swatch h-full left-0 opacity-1 overflow-hidden absolute top-0 transition-opacity width-full">
                            <img
                              src="https://fast.wistia.com/embed/medias/ppgmrzjgt5/swatch"
                              className="filter blur-sm h-full object-contain width-full"
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <svg
                  className="h-44 w-auto sm:h-64 md:h-64 2xl:h-80"
                  width="198"
                  height="518"
                  viewBox="0 0 198 518"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 15V7.5H15H83C142.371 7.5 190.5 55.6294 190.5 115V403C190.5 462.371 142.371 510.5 83 510.5H15H7.5V503V15Z"
                    fill="#E3675B"
                    stroke="black"
                    strokeWidth="15"
                  />
                  <circle
                    className="animate-pulse"
                    cx="83.5"
                    cy="288.5"
                    r="39.5"
                    fill="black"
                  />
                  <circle cx="83" cy="117" r="19" fill="black" />
                  <circle cx="48" cy="150" r="19" fill="black" />
                  <circle cx="118" cy="150" r="19" fill="black" />
                  <circle cx="83" cy="186" r="19" fill="black" />
                  <rect
                    x="36"
                    y="359"
                    width="31"
                    height="31"
                    rx="3"
                    fill="black"
                  />
                </svg>
              </div>

              <div
                className="max-w-xl mx-auto text-center sm:order-1 xl:max-w-3xl"
                ref={textRef}
              >
                <div className="space-y-4 text-link">
                  <h2 className="font-Orbitron text-2xl lg:text-4xl">
                    What Are The Most Important Areas Of Life? 
                  </h2>
                  <p>
                    Ask 100 people what it means to be happy, and you'll get 100
                    different answers. But what if there was a unified answer
                    rooted in universal principles that have existed since the
                    beginning of time? In today's fast-paced world, the concept
                    of the 5 Core Areas of Life offers a powerful framework for
                    personal growth and happiness.
                  </p>
                  <p>
                    These core aspects of life are the culmination of timeless
                    wisdom, impacting your everyday life and overall well-being.
                    They encompass those critical types of goals and life
                    categories that contribute to a well balanced life, and are
                    influenced by the habits we develop.
                  </p>
                  <p>
                    Unfortunately, the system we've grown up in has often
                    instilled failure habits, holding us back from achieving
                    true personal development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "absolute right-6 slide-in-bottom slide-in-bottom--slow w-28 sm:w-32 lg:right-12 xl:w-44 2xl:w-48",
            rocketShipFlying ? "z-10" : "z-0"
          )}
          style={{ top: getRocketOffset() }}
        >
          <StaticImage
            alt="Rocketship"
            placeholder="blurred"
            src="../images/rocket.webp"
          />
        </div>
      </div>
      <div className="mx-auto mb-12 ">
        <div className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
          <p>
            Imagine yourself as a rocket ship, with each of the 5 core areas as
            the thrusters propelling you towards the stars, each powered by your
            habits. 
          </p>
          <p>
            Your mission is to master a balanced life of continual growth in all
            5 areas of life by replacing failure habits with success habits. As
            you fuel these areas, you'll gain more momentum, moving you up into
            that bright, bold, beautiful sky to discover all the universe has to
            offer.
          </p>
          <p>
            In this holistic self improvement guide, we'll explore each of these
            core life areas in depth, uncovering the underlying principles
            driving overall health and success in each domain to help. We'll
            discuss how these 5 aspects of life interconnect and why life
            balance is crucial to well being in your own life cores. 
          </p>
          <p>
            Are you ready to step into the pilot seat of your life's rocket and
            fire on all cylinders? Let's dive into the 5 Core Areas of Life and
            begin your journey towards life balance and lasting happiness.
          </p>
          <div className="gap-4 grid grid-cols-1 sm:grid-cols-4 xl:gap-10 lg:grid-cols-3 mt-4">
            <OnScreen
              className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__repeat-2"
              classToAdd="scale-in-center slide"
            >
              <>
                <div className="bg-white h-full p-6 relative rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                  <div className="flex items-center mb-8">
                    <div className="flex h-14  items-center justify-center p-3 w-20 md:w-32">
                      <StaticImage
                        alt="Tedx"
                        className="w-32 sm:w-auto"
                        src="../images/5-cores.png"
                      />
                    </div>
                    <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                      Discover your 5 Life Cores {/* WHO you are */}
                    </h3>
                  </div>

                  <p className="text-link text-black">
                    <>
                      Pinpoint the pain points and related bad habits stifling
                      your growth in the 5 Core Areas Of  Life scientifically
                      linked to deep, soul-filling, long-term happiness 
                    </>
                  </p>
                </div>
              </>
            </OnScreen>

            <OnScreen
              className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-3s"
              classToAdd="scale-in-center"
            >
              <div className="bg-white h-full p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                <div className="flex items-center mb-8">
                  <div className="flex h-14  items-center justify-center p-3 w-20 md:w-32">
                    <StaticImage
                      alt="Tedx"
                      className="w-32 sm:w-auto"
                      src="../images/change-habits.png"
                    />
                  </div>
                  <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                    Change Your Habits{" "}
                  </h3>
                </div>
                <p className="text-link text-black">
                  Shine a spotlight on the harmful habits halting your momentum
                  in all 5 Cores, and the healthy habits to replace them with. 
                </p>
              </div>
            </OnScreen>

            <OnScreen
              className="sm:col-span-2 sm:col-start-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-5s"
              classToAdd="scale-in-center"
            >
              <div className="bg-white h-full p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                <div className="flex items-center mb-8">
                  <div className="flex h-14  items-center justify-center p-3 w-20 md:w-32">
                    <StaticImage
                      alt="Tedx"
                      className="w-32 sm:w-auto"
                      src="../images/game-controller.png"
                    />
                  </div>
                  <h3 className="font-Orbitron  text-blue text-xl xl:text-2xl">
                    Gamify Your Life{" "}
                  </h3>
                </div>

                <p className="text-link text-black">
                  Adopt an AI driven, gamified accountability system to make it
                  simple, fun, and rewarding to build and sustain momentum.
                </p>
              </div>
            </OnScreen>
          </div>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-16">
          <h2 className="font-Orbitron text-2xl lg:text-4xl">
            Understanding the 5 Core Areas of Life
          </h2>
          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-mindset-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores mindset"
                  placeholder="blurred"
                  src="../images/mindset-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:text-left"
              classToAdd="slide-in-right"
            >
              <h2 className="font-Orbitron text-2xl lg:text-4xl">
                1) Mindset Core 
              </h2>
              <br />
              <p className="font-bold">Your Command Center</p>
              <br />
              <p className="text-center font-bold">
                {" "}
                "Life is a game, how you perceive it determines how much you'll
                win."{" "}
              </p>
              <br />
              <p>
                The Mindset Core serves as the crucial foundation for growth in
                all other cores areas of life. It's not just about positive
                thinking; it's about developing a "growth owner" mentality that
                transforms how you perceive and interact with the world.
              </p>
              <br />
              <p>
                Remember, your mind can be your greatest asset or your biggest
                liability. In a world that often prioritizes the wrong things,
                developing the right mindset allows you to cut through the noise
                to focus and execute on what truly matters to ensure continuous
                growth.
              </p>
              <br />
              <p>
                By cultivating a resilient, growth-oriented mindset, you're
                better equipped to navigate life's ups and downs and maintain
                momentum towards your goals in all core aspects of life. 
              </p>
            </OnScreen>
          </div>
          <div className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl">
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                <b>Key Aspects:</b>
              </h3>
              <p>
                <b> Growth Mindset Oriented:</b> Belief that your abilities can
                be improved through effort, learning, and persistence.
              </p>
              <li className="pl-6 mb-0">
                <b> Related Universal Principle:</b>{" "}
                <i>
                  "Whether you believe you can do something or not, you are
                  right." - Henry Ford
                </i>
              </li>
              <p>
                <b>Failing Forward & Adapting:</b> Embrace failures as
                learning/growth opportunities.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>“Failure is mandatory for growth.”</i>
              </li>
              <p>
                <b>Obstacle Reframing: </b>View challenges as temporary
                roadblocks waiting for solutions.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>"If at first you don’t succeed, try try again.” </i>
              </li>
              <p>
                <b>Fear Conquering:</b> Push beyond your comfort zone.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i> “Life begins at the end of your comfort zone.”</i> 
              </li>
              <p>
                <b>Gratitude:</b> Focusing on the good
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i> “Gratitude turns what we have into enough."</i>
              </li>
            </OnScreen>
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-right"
            >
              <h3 className="font-Orbitron text-coral text-xl xl:text-2xl">
                Top Related Pain Points:{" "}
              </h3>{" "}
              <ol className="pl-6 order-list">
                <li>
                  Fixed mindsetconvincing self that abilities and intelligence
                  are static and can’t be developed.
                </li>
                <li>
                  Low confidence hindering personal and professional growth.
                </li>
                <li>Fear of failure leading to missed opportunities.</li>
                <li>Negative self-talk and limiting beliefs:</li>
                <li>Feeling stuck when facing life's challenges.</li>
              </ol>
            </OnScreen>
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                Player Progression: Fixed-Victim vs. Growth-Owner Mindset Habits
              </h3>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Beginning Player (Fixed-Victim Habits)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Advanced Player (Growth-Owner Habits)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Avoids challenges to prevent potential failure
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Morning routine of reviewing/prioritizing stretch goals
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Consistently abandoning projects at the first sign of
                      difficulty
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Problem-solving routine when facing obstacles
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Continuous barrage of negative self-talk after mistakes
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Routine of reflective journaling to learn and grow
                    </td>
                  </tr>
                </tbody>
              </table>
            </OnScreen>
          </div>

          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full lg:order-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-finance-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores career finance"
                  placeholder="blurred"
                  src="../images/finance-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:order-1 lg:text-left"
              classToAdd="slide-in-left"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                <h2 className="font-Orbitron text-2xl lg:text-4xl">
                  2) Career & Finance Core
                </h2>
                <br />
                <p>
                  <b>Your Wealth Engine</b>
                </p>
                <br />
                <p>
                  This core is about aligning your work with your purpose,
                  leveraging your strengths and passions, and creating
                  exponential wealth along the way. There’s a focus  on changing
                  your mindset and relationship with money to make it work for
                  you while pursuing a career area in line with your core
                  values. 
                </p>
                <p>
                  Your career isn't just a means to pay bills; it's a
                  significant part of your life's purpose and personal
                  fulfillment. Similarly, financial health isn't about hoarding
                  wealth, but about creating a foundation that supports your
                  goals and values across all <i>core areas of life</i>.
                </p>
                <p>
                  The most gratifying career involves combining your strengths
                  and passions to leave the world better than you entered it.
                  This path will harness the power of compound interest in both
                  your finances and happiness,{" "}
                </p>
              </div>
            </OnScreen>
          </div>
          <div className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl">
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                <b>Key Aspects:</b>
              </h3>
              <p>
                <b> Purpose Alignment:</b> Connect your career to your life's
                mission and values.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle: </b>{" "}
                <i>
                  "Choose a job you love, and you will never have to work a day
                  in your life." – Confucius. 
                </i>
              </li>
              <p>
                <b>Strength & Passion Utilization:</b> Leverage your natural
                talents, acquired skills, and areas of life that excite you the
                most. 
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>Do what you do best, outsource the rest." – Peter Drucker</i>
              </li>
              <p>
                <b>Financial Intelligence: </b>Develop a growth-oriented
                relationship with money and use the law of compounding to reach
                your financial goals.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>
                  "Wealth is not his that has it, but his that enjoys it." –
                  Benjamin Franklin. 
                </i>
              </li>
              <p>
                <b>Efficiency, Productivity, Persistence, and Resilience:</b>{" "}
                Maximize your output by prioritizing, implementing effective
                systems, and leveraging automation, while also pushing through
                tough times that defeat many others.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i> "Work smarter, not harder."</i> 
              </li>
              <p>
                <b>Continuous Learning and Adaptation:</b> Invest in
                continuously learning the ins and outs of your career area and
                look ahead to where your industry is headed.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i>
                  {" "}
                  An investment in knowledge pays the best interest." – Benjamin
                  Franklin
                </i>
              </li>
            </OnScreen>
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-right"
            >
              <h3 className="font-Orbitron text-coral text-xl xl:text-2xl">
                Top Related Pain Points:{" "}
              </h3>{" "}
              <ol className="pl-6 order-list">
                <li>
                  Feeling unfulfilled in a career that doesn't align with
                  personal values.
                </li>
                <li>Fear of failure squashing true potential.</li>
                <li>Lack of clear career goals and direction. </li>
                <li>
                  Struggling with financial management and long-term wealth
                  building.
                </li>
                <li>
                  Difficulty balancing career ambitions with other life
                  priorities.
                </li>
              </ol>
            </OnScreen>
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                Player Progression: Paycheck Survivor vs. Purpose-Driven
                Entrepreneur
              </h3>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Beginning Player (Paycheck Survivor)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Advanced Player (Purpose-Driven Entrepreneur)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Passively accepts current job situation
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Regularly self-assesses and plans career growth
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Spends impulsively without a financial plan
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Automates savings and investment routines
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Avoids financial education due to overwhelm
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Engages in weekly business and finance learning
                    </td>
                  </tr>
                </tbody>
              </table>
            </OnScreen>
          </div>
          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-relationship-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores relationship"
                  placeholder="blurred"
                  src="../images/relationship-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:text-left"
              classToAdd="slide-in-right"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                <h2 className="font-Orbitron text-2xl lg:text-4xl">
                  3) Relationship Core
                </h2>
                <br />
                <p className="font-bold">Your Crew</p>
                <br />
                <p>
                  The Relationship Core is the backbone of human existence,
                  often serving as a cornerstone of happiness and emotional
                  well-being. It encompasses creating and maintaining deep,
                  fulfilled connections and gaining allies to help you achieve
                  your goals. 
                </p>
                <p>
                  In today's digital age, this core can help deemphasize
                  reliance on screens and feelings of isolation to instead
                  emphasize real, meaningful relationships that enhance trust,
                  understanding, and mutual growth. It's about developing
                  emotional intelligence (EQ) to better understand and regulate
                  your emotions while being receptive to others'.{" "}
                </p>
                <p>
                  Strong bonds, whether they be family members, a romantic
                  relationship, friendships, community involvement, or
                  colleagues, are crucial for a fulfilling life and overall
                  well-being.
                </p>
              </div>
            </OnScreen>
          </div>
          <div className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl">
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                <b>Key Aspects:</b>
              </h3>
              <p>
                <b> Emotional Intelligence:</b> Develop the ability to
                understand and manage your emotions and those of others.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle: </b>{" "}
                <i>
                  "Trust takes years to build, seconds to break, and forever to
                  repair."
                </i>
              </li>
              <p>
                <b>Trust Building: </b> Foster trust through consistent, honest,
                and supportive interactions.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>
                  "Trust takes years to build, seconds to break, and forever to
                  repair."
                </i>
              </li>
              <p>
                <b>Effective Communication: </b>Practicing active listening and
                clear expression.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>
                  "Seek first to understand, then to be understood." – Stephen
                  Covey
                </i>
              </li>
              <p>
                <b>Empathy and Compassion:</b> Connecting on a Deeper Emotional
                Level
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i>
                  {" "}
                  "Don’t judge until you’ve walked a mile in someone else’s
                  shoes.”
                </i>
                 
              </li>
              <p>
                <b>Mutual Respect and Healthy Boundaries: </b> Honoring
                Individual Needs and Limits
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i>”Do unto others as you would have them do unto you.”</i>
              </li>
            </OnScreen>
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-right"
            >
              <h3 className="font-Orbitron text-coral text-xl xl:text-2xl">
                Top Related Pain Points:{" "}
              </h3>{" "}
              <ol className="pl-6 order-list">
                <li>
                  Feeling isolated and struggling to form deep, meaningful
                  connections.
                </li>
                <li>
                  Low emotional intelligence (EQ) leading to conflict and
                  miscommunication.
                </li>
                <li>
                  Fear of Rejection and Inadequacy in Social Interactions: 
                </li>
                <li>
                  Difficulty in maintaining balanced and healthy relationship
                  dynamics.
                </li>
                <li>
                  Challenges in effectively communicating feelings and needs to
                  others.
                </li>
              </ol>
            </OnScreen>
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                Player Progression: Social Wallflower vs. Relationship Maven
              </h3>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Beginning Player (Social Wallflower)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Advanced Player (Relationship Maven)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Avoids social situations due to discomfort
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Regularly initiates conversations and social
                      engagements
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Neglects keeping in touch with friends and family life
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Schedules check-ins with important people in their life
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Focuses solely on own needs in interactions
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Actively seeks ways to add value to others' lives
                    </td>
                  </tr>
                </tbody>
              </table>
            </OnScreen>
          </div>

          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full lg:order-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-physical-health-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores physical health"
                  placeholder="blurred"
                  src="../images/physical-health-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:order-1 lg:text-left"
              classToAdd="slide-in-left"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                <h2 className="font-Orbitron text-2xl lg:text-4xl">
                  4) Physical Health Core{" "}
                </h2>
                <br />
                <p className="font-bold">Your Structural Integrity</p>
                <br />
                <p>
                  The Physical Health Core is about taking care of your physical
                  self to look good, feel good, and sustain the energy and
                  stamina to propel you through life. Science has proven that
                  the mind and body are intricately connected, so neglecting to
                  move or grow in either kills your momentum in both. 
                </p>
                <p>
                  Physical health forms the foundation upon which you can build
                  success in all other cores, drastically improving your overall
                  quality of life. It provides the energy, clarity, and
                  resilience needed to tackle life's challenges and accomplish
                  your goals in your other cores with vigor. 
                </p>
                <p>
                  Small, consistent habits in physical health can lead to
                  significant improvements in your overall well-being and
                  personal growth across all areas of life.
                </p>
              </div>
            </OnScreen>
          </div>
          <div className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl">
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              {" "}
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                <b>Key Aspects:</b>
              </h3>
              <p>
                <b> Regular Exercise:</b> Engage in both cardiovascular and
                strength training activities.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle: </b>{" "}
                <i>"Move it or lose it."</i>
              </li>
              <p>
                <b>Nutrition and Hydration: </b> Fuel your body with a healthy
                balanced diet and proper hydration.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>"You are what you eat."</i>
              </li>
              <p>
                <b>Sleep and Recovery: </b>Prioritize quality sleep and allow
                time for physical recovery.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>"Your future depends on your dreams, so go to sleep."</i>
              </li>
              <p>
                <b>Injury Prevention and Awareness:</b> Stay mindful of your
                body and external factors affecting your health.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i>"An ounce of prevention is worth a pound of cure."</i> 
              </li>
              <p>
                <b>Moderation: </b> Balance in substances and lifestyle
                choices. 
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i>"Too much of anything is a bad thing."</i>
              </li>
            </OnScreen>
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-right"
            >
              <h3 className="font-Orbitron text-coral text-xl xl:text-2xl">
                Top Related Pain Points:{" "}
              </h3>{" "}
              <ol className="pl-6 order-list">
                <li>
                  Lack of energy and stamina to pursue daily activities and
                  long-term goals.
                </li>
                <li>
                  Difficulty maintaining a consistent exercise routine due to
                  time constraints or lack of motivation.
                </li>
                <li>
                  Struggling with weight management and making healthy dietary
                  choices.{" "}
                </li>
                <li>Poor Sleep Quality and Insufficient Rest:</li>
                <li>
                  Frequent Injuries or Health Issues Due to Lack of Awareness or
                  Poor Technique.
                </li>
              </ol>
            </OnScreen>
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                Player Progression: Couch Potato vs. Wellness Warrior{" "}
              </h3>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Beginning Player (Couch Potato)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Advanced Player (Wellness Warrior)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Sedentary lifestyle with minimal physical activity
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Regular, varied exercise routine tailored to personal
                      preferences
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Inconsistent sleep patterns and poor sleep hygiene
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Consistent sleep schedule with proper wind-down routine
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Reliance on processed, convenience foods
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Meal planning using whole, nutrient-dense foods for a
                      healthy diet
                    </td>
                  </tr>
                </tbody>
              </table>
            </OnScreen>
          </div>
          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-mental-health-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores emotional health"
                  placeholder="blurred"
                  src="../images/emotional-health-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:text-left"
              classToAdd="slide-in-right"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                <h2 className="font-Orbitron text-2xl lg:text-4xl">
                  5) Mental & Emotional Health Core
                </h2>
                <br />
                <p className="font-bold">Your Shields and Blasters</p>
                <br />
                <p>
                  Mental and Emotional health, also known as psychological
                  health, involves your ability to effectively manage and
                  nurture your thoughts and feelings in order to minimize stress
                  and maximize your human spirit. It’s the foundation to build a
                  life of purpose, fulfillment, and personal growth, ensuring
                  that you're not just succeeding in life, but truly thriving
                  and making a positive impact on the world around you.
                </p>
                <br />
                <p>
                  The Mental Health aspect includes our cognitive abilities and
                  our social well-being. It involves your ability to think,
                  learn, and understand your environment and the experiences we
                  go through. A person with strong mental health typically has
                  good cognitive skills, including stress management,
                  problem-solving, critical thinking, and decision-making
                  abilities. 
                </p>
                <br />
                <p>
                  Emotional Health is a subset of mental health with a focus on
                  managing your emotions effectively throughout the challenges
                  that are a natural part of life. It's about replacing negative
                  emotions with positive ones at will, coping and rebounding
                  from setbacks, adaptability to change, and ensuring regular
                  expression of your passions.
                </p>
              </div>
            </OnScreen>
          </div>
          <div className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl">
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              {" "}
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                <b>Key Aspects:</b>
              </h3>
              <p>
                <b>Emotional Regulation:</b> Learn to understand and control
                your emotional responses (i.e. anger, sadness, joy, and love).
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>"Feel your feelings; don't become them."</i>
              </li>
              <p>
                <b>Stress Management:</b>Develop effective techniques to handle
                life's pressures.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>{" "}
                <i>
                  "You can't stop the waves, but you can learn to surf." – Jon
                  Kabat-Zinn.
                </i>
              </li>
              <p>
                <b>Mindfulness and Self-Awareness:</b> Practice being present
                and understanding your inner world in order to influence the
                outer.
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i>"Be present and live in the here and now.” </i> 
              </li>
              <p>
                <b>Enjoying the ride: </b> Stopping to smell the roses and
                proactively working your passions into your routine and{" "}
                <b>free time. </b> 
              </p>
              <li className="pl-6 mb-0">
                <b>Related Universal Principle:</b>
                <i>
                  "No act of kindness, no matter how small, is ever wasted."
                </i>
              </li>
            </OnScreen>{" "}
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto  text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-right"
            >
              <h3 className="font-Orbitron text-coral text-xl xl:text-2xl">
                Top Related Pain Points:{" "}
              </h3>{" "}
              <ol className="pl-6 order-list">
                <li>Chronic stress and difficulty managing daily pressures.</li>
                <li>
                  Emotional volatility and inability to control reactions to
                  situations.
                </li>
                <li>
                  Lack of purpose or feeling unfulfilled in life - including
                  spiritual growth.
                </li>
                <li>
                  Difficulty Being Present and Mindful Due to Overthinking or
                  Anxiety.
                </li>
                <li>Struggles with Resilience and Adapting to Change.</li>
              </ol>
            </OnScreen>{" "}
            <OnScreen
              className="container text-left  gap-8 grid grid-cols-1 items-center mx-auto text-black md:grid-cols-1 2xl:max-w-7xl"
              classToAdd="slide-in-left"
            >
              <h3 className="font-Orbitron text-blue text-xl xl:text-2xl">
                {" "}
                Player Progression: Emotional Rollercoaster vs. Zen Master{" "}
              </h3>
              <table
                className="mb-4"
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Beginning Player (Emotional Rollercoaster)
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Advanced Player (Zen Master)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Reactive emotional responses to situations
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      1. Daily mindfulness or meditation practice for inner
                      peace
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Neglecting self-care and personal passions
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      2. Regular engagement in activities that bring joy and
                      fulfillment
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Avoiding difficult emotions or conversations
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      3. Practicing emotional intelligence in daily interaction
                    </td>
                  </tr>
                </tbody>
              </table>
            </OnScreen>
          </div>
        </div>
      </div>
      <div className="bg-gray py-12 mt-4">
        <div className="max-w-full px-4 lg:container md:mx-auto">
          <h2 className="font-Orbitron  text-center text-3xl sm:mb-8 lg:text-4xl">
            How To Win At Life In All 5 Cores
          </h2>
          <NewTimeline events={newEvents} />
        </div>
      </div>
      <div className="bg-white mt-12">
        {" "}
        <div className="container text-left py-12  gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
          <h2 className="font-Orbitron relative text-3xl text-underline lg:text-4xl">
            The Importance of Tracking All Areas of Life{" "}
          </h2>
          <p>
            In our data-driven world, it's crucial to remember that not
            everything important is easily measurable. Tracking all areas of
            life is essential for:
          </p>
          <ol className="order-list pl-6">
            <li>
              Holistic Growth: Find balance across all five aspects of life.
            </li>
            <li>
              Identifying Blind Spots: Address potential issues before they
              become significant problems.
            </li>
            <li>
              Synergistic Effects: Leverage progress in one area to boost
              others.
            </li>
            <li>
              Motivation and Accountability: Stay motivated and accountable for
              your personal development goals.
            </li>
            <li>
              Informed Decision Making: Get a galactic view of your life to
              improve your goal setting. 
            </li>
          </ol>
          <p>
            Remember, improvements in areas like emotional intelligence or
            relationship quality are equally important but harder to measure.
            Moore Momentum encourages tracking these less tangible but crucial
            aspects of life with our{" "}
            <a className="link" href="https://habitscoach.ai/" target="_blank">
              ai powered goal setting system
            </a>
            . 
          </p>
          <p>
            By monitoring all five life cores, you can better answer, "What
            areas of your life need the most improvement?" This awareness is the
            first step towards meaningful change and overall life satisfaction.
          </p>
          <p>
            Start with consistent check-ins for each core self improvement area
            using our{" "}
            <Link className="link" to="/weekly-habit-tracker-app/">
              weekly habit tracker app
            </Link>
            .  Over time, you'll develop a nuanced understanding of your
            progress and the interconnectedness of these life areas. Tracking
            progress in different areas of life helps you find balance within
            your personal growth goals.
          </p>
          <p>
            Aim for steady progress and balance, not perfection, adjusting your
            goal setting along the way.. By tracking all areas of life, you're
            ensuring that you're truly thriving across the board, not just
            succeeding in one or two aspects.
          </p>
          <h2 className="font-Orbitron relative text-3xl text-underline lg:text-4xl">
            Achieving Balance and Growth Across Your 5 Core Areas Of Life{" "}
          </h2>
          <p>
            Maintaining a balanced life across all 5 pillars is crucial for
            experiencing life's full potential. Just as the gears of your rocket
            must fit together in order to work, you need to develop all five
            cores to build the momentum needed to level-up in life.
          </p>
          <div className="flex lg:flex-row flex-col w-full items-center lg:justify-between justify-center ">
            <img src={gears} alt="gears spinning" />
            <img
              alt="rocket habits builder"
              className="lg:mt-0 mt-6"
              src={rocket}
            />
          </div>
        </div>
      </div>
      <div className="mt-12">
        {" "}
        <div className="container text-left py-12  gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
          <h2 className="font-Orbitron relative text-3xl text-underline lg:text-4xl">
            Key strategies for achieving balance and growth:{" "}
          </h2>
          <ol className="order-list pl-6">
            <li>
              <b> Personalize Your Approach:</b> Maximize each core according to
              your individuality. Use our{" "}
              <a
                className="link"
                href="https://habitscoach.ai/"
                target="_blank"
              >
                ai powered habits coach
              </a>{" "}
              to determine those golden habits to help minimize friction. 
            </li>
            <li>
              <b> Weekly 10k View Reviews:</b> Implement weekly reviews of all
              five core aspects of life to maintain momentum with your personal
              goals. Use our{" "}
              <Link className="link" to="/weekly-habit-tracker-app/">
                weekly habit tracker app
              </Link>
              .
            </li>
            <li>
              <b> Balance Doesn't Mean Equal Time:</b> Give appropriate
              attention to each area based on current life stage and goals. And
              beware solely focusing on your financial goals at the expense of
              your other cores. 
            </li>
            <li>
              <b>Leverage Core Synergies:</b> Recognize how progress in one life
              core can positively impact others.
            </li>
            <li>
              <b>Adapt to Life Changes:</b> Be prepared to adjust your balance
              as life circumstances change.
            </li>
          </ol>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 space-y-16">
        <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
          <OnScreen
            className="mx-auto p-4 lg:order-2"
            classToAdd="scale-in-center"
          >
            <img alt="will moore 5 cores physical health" src={rocketcores} />
          </OnScreen>

          <OnScreen
            className="text-center lg:order-1 lg:text-left"
            classToAdd="slide-in-left"
          >
            <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
              <p>
                Finding the right work-life balance is key to thriving in all
                aspects of life. Consider how your everyday life choices impact
                your progress across the 5 areas of life.
              </p>
              <p>
                Remember, the goal isn't perfection in all areas at all times.
                Strive for steady progress and overall life balance. By
                maintaining this holistic approach, you're setting yourself up
                to "fire on all cylinders" and conquer the far reaches of the
                universe.
              </p>

              <p>
                Ultimately, achieving balance across your 5 Core Areas is about
                creating a life that aligns with your deepest values and
                long-term vision, ensuring that you're truly thriving across all
                parts of life. By focusing on these key life areas, you're on
                your way to living your best life and achieving true life
                balance.
              </p>
            </div>
          </OnScreen>
        </div>
      </div>
      <div className="container text-left py-12  gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
        <h2 className="font-Orbitron relative text-3xl text-underline lg:text-4xl">
          Begin Your Journey{" "}
        </h2>
        <p>
          Are you ready to fire on all cylinders in your 5 core aspects of
          life? 
        </p>
        <p>
          Start your journey with this simple{" "}
          <Link className="link" to="/core-values-quiz/">
            {" "}
            core values quiz
          </Link>{" "}
          to determine where you currently stand in each of your 5 core aspects
          of life. 
        </p>
        <Link className="link" to="/core-values-quiz/">
          <img className="m-auto" src={reset} alt="rest life" />
        </Link>
        <img
          src="https://images.ctfassets.net/8kplw9flsxgi/2y8MrT53rYecyXJNxWOpdE/1174a1daa05aae4990e5f0444379bc79/Untitled_design__2_.jpg"
          alt="ganified life"
          className="euqation m-auto"
          style={{ width: "70%" }}
        />
      </div>
      {/* <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-end my-16 sm:mt-32 xl:mt-48">
          <div className="bg-white max-w-lg mb-12 mx-4 relative shadow-lg lg:mx-0">
            <div className="absolute -left-12 spin spin--slow -top-12 -z-1 xl:-left-24 xl:-top-24">
              <svg
                className="system-svg"
                width="401"
                height="401"
                viewBox="0 0 401 401"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="200.5"
                  cy="200.5"
                  r="188"
                  stroke="#FF4242"
                  strokeWidth="25"
                  strokeDasharray="6 23"
                />
              </svg>
            </div>

            <div className="max-w-sm">
              <img alt="a broken system" src={happyMeter} loading="lazy" />
            </div>
          </div>

          <div className="bg-gray -mt-32 -mx-4 pb-12 pt-36 rounded-md lg:-ml-24 lg:mt-0 lg:py-12 lg:flex-1">
            <div className="mx-auto px-8 lg:w-2/3">
              <h2 className="font-Orbitron relative text-3xl text-underline lg:text-4xl">
                Begin Your Journey
              </h2>

              <div className="mb-8 space-y-4 text-link">
                {renderRichText(newData.mission, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>

              <StartButton className="py-3 px-12 bg-coral rounded-full text-sm text-white" />
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export default FiveCores
